import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Event, GuardsCheckEnd, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private history: string[] = [];

  constructor(
    private router: Router,
    private location: Location
  ) {}

  init(): void {
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe((e: RouterEvent | Event) => {
        if (e instanceof GuardsCheckEnd) this.history.push(e.urlAfterRedirects);
      });
  }

  navigateByUrl(url: string): Promise<boolean> {
    return this.router.navigateByUrl(url);
  }

  back(): void {
    this.history.pop();
    this.history.length > 0 ? this.location.back() : this.router.navigateByUrl('/');
  }
}
